<template>
  <div
    :id="modalId"
    :class="classes"
    :style="this.styles"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Solicitar Acesso</h5>
          <button type="button" class="close" @click="hide" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form @submit.prevent="save" class="form-horizontal">
          <div class="modal-body">
            <div class="form-body p10">
              <div class="form-group row">
                <div class="col-md-12 col-lg-12">
                  <label class="control-label text-right"
                    >Nome de exibição</label
                  >
                  <input
                      v-model="form.display_name"
                      type="text"
                      class="form-control"
                      required
                    />
                </div>
                <div class="col-md-6 col-lg-6 hide">
                  <label class="control-label text-right"
                    >CNPJ</label
                  >
                  <input
                      v-model="form.identification"
                      v-mask="'##.###.###/####-##'"
                      type="text"
                      class="form-control"
                    />
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-6 col-lg-6">
                  <label class="control-label"
                    >Número de telefone</label>
                    <vue-tel-input
                      ref="phone"
                      v-model="form.phone"
                      @country-changed="changeCountry"
                      v-bind="bindProps"
                    ></vue-tel-input>
                    <div class="invalid-feedback" :class="{ show: invalidPhone }">
                      {{$t('login-invite.invalid-phone')}}
                    </div>
                </div>
                <div class="col-md-6 col-lg-6">
                  <label class="control-label text-right"
                    >Email de contato</label
                  >
                  <input
                      v-model="form.email"
                      type="text"
                      class="form-control"
                    />
                </div>
              </div>
              <hr>
              <h3>Detalhes da Conta</h3>
              <div class="form-group row hide">
                <div class="col-md-12 col-lg-12">
                  <label class="control-label text-right"
                    >{{$t('departments.data-infos.lbl-description')}}</label
                  >
                  <textarea
                      v-model="form.description"
                      class="form-control"></textarea>
                </div>
              </div>
              <div class="form-group row hide">
                <div class="col-md-12 col-lg-12">
                  <label class="control-label text-right"
                    >{{$t('billing.prefs.lbl-address')}}</label
                  >
                  <textarea
                      v-model="form.address"
                      class="form-control"></textarea>
                </div>
              </div>
              <div class="form-group row hide">
                <div class="col-md-12 col-lg-12">
                  <label class="control-label"
                    >{{$tc('generic-str.category', 1)}}</label
                  >
                  <select v-model="form.category" class="form-control">
                    <option value="Automotive">Auto</option>
                    <option value="Beauty, Spa, and Salon">Beleza, Spa, e salão</option>
                    <option value="Clothing and Apparel">Roupas e vestuário</option>
                    <option value="Education">Educação</option>
                    <option value="Entertainment">Entretenimento</option>
                    <option value="Event Planning and Service">Serviço de eventos</option>
                    <option value="Finance and Banking">Finanças e Bancos</option>
                    <option value="Food and Grocery">Alimentação</option>
                    <option value="Public Service">Serviços Público</option>
                    <option value="Hotel and Lodging">Hotel e Hospedagem</option>
                    <option value="Medical and Health">Serviços de Saúde</option>
                    <option value="Professional Services">Autônomo</option>
                    <option value="Shopping and Retail">Compras e varejo</option>
                    <option value="Professional Services">Autônomo</option>
                    <option value="Travel and Transportation">Turísmo e transporte</option>
                    <option value="Restaurant">Restaurante e Bares</option>
                    <option value="Other">Outros</option>
                  </select>
                </div>
              </div>
              <div class="form-group row hide">
                <div class="col-md-12 col-lg-12">
                  <label class="control-label text-right"
                    >Site</label
                  >
                  <input
                      v-model="form.site"
                      type="text"
                      class="form-control"
                    />
                </div>
              </div>
              <h3 class="hide">Imagens</h3>
              <div class="form-group row">
                <div class="col-md-8 col-lg-8 hide">
                  <label class="control-label text-right"
                    >Imagem grande do banner (1440 x 448)</label>
                  <div class="image-cover" id="cover-banner"></div>
                  <a @click="callCrop('cover-banner', 1440, 448, 0)" class="c-pointer">Fazer upload</a>
                </div>
                <div class="col-md-4 col-lg-4">
                  <label class="control-label text-right"
                    >Logo (224 x 224)</label>
                  <div class="image-cover" id="cover-logo"></div>
                  <a @click="callCrop('cover-logo', 224, 224, 1)" class="c-pointer">Fazer upload</a>
                </div>
                <div class="col-md-8 col-lg-8">
                  <div class="row">
                    <div class="col-6">
                      <label class="control-label text-left">Horário de início</label>
                      <input type="time" class="form-control" v-model="form.primary_interaction.availability[0].start_time">
                    </div>
                    <div class="col-6">
                      <label class="control-label text-left">Horário de término</label>
                      <input type="time" class="form-control" v-model="form.primary_interaction.availability[0].end_time">
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <label class="control-label text-left">Dia de Início</label>
                      <select class="form-control" v-model="form.primary_interaction.availability[0].start_day">
                        <option value="SUNDAY">Domingo</option>
                        <option value="MONDAY">Segunda</option>
                        <option value="TUESDAY">Terça</option>
                        <option value="WEDNESDAY">Quarta</option>
                        <option value="THURSDAY">Quita</option>
                        <option value="FRIDAY">Sexta</option>
                        <option value="SATURDAY">Sábado</option>
                      </select>
                    </div>
                    <div class="col-6">
                      <label class="control-label text-left">Dia Fim</label>
                      <select class="form-control" v-model="form.primary_interaction.availability[0].end_day">
                        <option value="SUNDAY">Domingo</option>
                        <option value="MONDAY">Segunda</option>
                        <option value="TUESDAY">Terça</option>
                        <option value="WEDNESDAY">Quarta</option>
                        <option value="THURSDAY">Quita</option>
                        <option value="FRIDAY">Sexta</option>
                        <option value="SATURDAY">Sábado</option>
                      </select>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <label class="control-label text-left">Timezone</label>
                      <select class="form-control" v-model="form.primary_interaction.availability[0].timezone">
                        <option value="America/Sao_Paulo">America/Sao_Paulo</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="progress" style="width: 100%;" v-if="isSending">
              <div
                class="progress-bar progress-bar-striped progress-bar-animated"
                role="progressbar"
                :style="`width: ${uploadProgress}%;`"
                aria-valuenow="25"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                {{ uploadProgress }}%
              </div>
            </div>
            <button type="button" class="btn btn-secondary" @click="hide">
              {{$t('generic-str.cancel')}}
            </button>
            <button
              :disabled="isSending"
              type="submit"
              :class="{ 'qt-loader qt-loader-mini qt-loader-right': isSending }"
              class="btn btn-primary"
            >
              Enviar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from '@/mixins/Modal';
import { VueTelInput } from 'vue-tel-input';
import { mask } from 'vue-the-mask';
import RCSService from '@/services/rcs.service';
import BucketService from '@/services/bucket.service';

export default {
  directives: {
    mask,
  },
  components: {
    VueTelInput,
  },
  mixins: [Modal],
  props: ['id'],
  data() {
    return {
      uploadProgress: 10,
      form: {
        phone_number: '',
        primary_interaction: {
          availability: [
            {
              end_day: 'SUNDAY',
              end_time: '07:00 PM',
              start_day: 'SUNDAY',
              start_time: '07:00 AM',
              timezone: 'America/Sao_Paulo',
            },
          ],
        },
      },
      isSending: false,
      invalidPhone: false,
      cropPreview: null,
      files: [{}, {}],
      bindProps: {
        mode: 'national',
        defaultCountry: 'BR',
        disabledFetchingCountry: false,
        disabled: false,
        disabledFormatting: true,
        placeholder: 'Número',
        required: true,
        enabledCountryCode: false,
        enabledFlags: true,
        validCharactersOnly: true,
        preferredCountries: ['AU', 'BR'],
        onlyCountries: [],
        ignoredCountries: [],
        autocomplete: 'off',
        name: 'telephone',
        maxLen: 25,
        wrapperClasses: '',
        inputClasses: 'form-control',
        dropdownOptions: {
          disabledDialCode: false,
        },
        inputOptions: {
          showDialCode: false,
        },
      },
    };
  },
  mounted() {
    this.$root.$on('call.crop', this.crop);
  },
  methods: {
    crop(data) {
      this.$root.$emit('show.modal', 'request-modal');
      if (data) {
        document.getElementById(this.cropPreview).style = `background: url(${data.url}); background-size: cover;`;
        this.files[data.name] = data;
      }

      console.log(this.files);
    },
    callCrop(cropPreview, maxWidthP, maxHeightP, index) {
      this.cropPreview = cropPreview;
      console.log(index);
      this.$store.dispatch('crop/config', {
        maxWidth: maxWidthP,
        maxHeight: maxHeightP,
        name: index,
        viewMode: 2,
        aspectRatio: 1,
        minSize: true,
      });

      this.$root.$emit('show.modal', 'm-crop');
      this.hide(false, false);
    },
    changeCountry(e) {
      const tempNumber = this.form.phone_number
        .replace(/\D/g, '')
        .split('(')[1];
      this.form.phone_number = `${e.dialCode}${tempNumber}`;
    },
    callBackProgress(progress) {
      this.uploadProgress = progress;
    },
    save() {
      if (this.files[1].file === undefined) {
        this.$toast.show({
          title: 'Aviso',
          content: 'Você precisa fazer uploado da logo',
          type: 'danger',
        });

        return;
      }

      this.isSending = true;

      const filesCrop = [];
      filesCrop.push({
        x: this.files[0].x,
        y: this.files[0].y,
        w: this.files[0].width,
        h: this.files[0].height,
      });
      filesCrop.push({
        x: this.files[1].x,
        y: this.files[1].y,
        w: this.files[1].width,
        h: this.files[1].height,
      });
      const formDataCover = new FormData();
      formDataCover.append('files[]', this.files[0].file);
      formDataCover.append('files[]', this.files[1].file);
      formDataCover.append('role', 'public');
      formDataCover.append('folder', 'rcs');
      formDataCover.append('crop', JSON.stringify(filesCrop));

      Promise.all([
        BucketService.upload(formDataCover, true, this.callBackProgress),
      ])
        .then(
          (responses) => {
            this.isSending = false;
            console.log(responses);
            // this.form.banner_url = responses[0].data[0].url;
            this.form.logo_url = responses[0].data[0].url;
            console.log(responses);
            this.createAgent();
          },
          (error) => {
            this.content = error;
            this.isSending = false;
            console.log(error);
          },
        )
        .finally(() => {
          this.isSending = false;
        });
    },
    createAgent() {
      this.isSending = true;
      RCSService.createAgent(this.form).then(
        (client) => {
          console.log(client);
          this.$root.$emit('rcsclient.submit', client.data);
          // this.hide(true);
          this.$toast.show({
            title: this.$t('generic-str.success'),
            content: 'Solicitação enviada',
            type: 'success',
          });
          this.$emit('agent.submit');
          this.isSending = false;
        },
        (error) => {
          this.$toast.show({
            title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
            content: error.message,
            type: 'danger',
          });
          this.isSending = false;
        },
      );
    },
  },
};
</script>

<style scoped lang="scss">
  .image-cover{
    border: 1px solid rgba(120,141,180,.3);
    width: 100%;
    height: 200px;
  }
</style>
