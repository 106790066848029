<template>
  <div class="content">
    <PageHeader title="RCS Google" />
    <section class="page-content container-fluid">
      <div class="row">
        <div class="col-sm-12" v-if="fetched">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-6">
              <div class="card">
                <div class="card-content">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-10">
                        <h4 class="card-title"><i class="mr-2 fas fa-comment-alt fa-fw"></i>RCS <span class="badge badge-danger" v-if="client.active">{{$t('generic-str.status.lbl-active')}}</span><span class="badge badge-danger" v-if="!client.active && client.status != 'waiting'">{{$t('generic-str.status.lbl-inactive')}}</span><span class="badge badge-warning" v-if="client.status == 'waiting'">{{$tc('generic-str.status.lbl-pending', 1)}}</span></h4>
                        <p class="card-text">Expanda o alcance do seu cliente por meio desse novo canal de atendimento, enviando mensagens simples e robustas.</p>
                        <a class="btn btn-primary"  v-modal="{ target: 'request-modal' }" :class="{'disabled': client.status == 'waiting'}" v-if="client.status != 'active'">Solicitar Acesso</a>
                        <router-link
                            to="/rcs/send"
                            class="btn btn-success m-left-5"
                            :class="{'disabled': client.status != 'active'}"
                          >Enviar mensagem</router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 hide">
              <div class="card padding-b-14">
                <div class="row m-0 col-border-xl">
									<div class="col-md-6 col-lg-6 col-xl-6">
										<div class="card-body">
                      <br>
											<div class="icon-rounded icon-rounded-primary float-left m-r-20">
												<i class="icon dripicons-graph-bar"></i>
											</div>
											<h5 class="card-title m-b-5 counter" data-count="0">0</h5>
											<h6 class="text-muted m-t-10">
												Mensagens enviadas
											</h6>

                      <br>
										</div>
									</div>
									<div class="col-md-6 col-lg-6 col-xl-6 hide">
										<div class="card-body">
                      <br>
											<div class="icon-rounded icon-rounded-accent float-left m-r-20">
												<i class="fas fa-dollar-sign f-w-600"></i>
											</div>
											<h5 class="card-title m-b-5  counter" data-count="3700">R$ 37,00</h5>
											<h6 class="text-muted m-t-10">
												Valor Gasto
											</h6>
											<div class="progress progress-total-revenue mt-4" style="height:7px;">
												<div class="progress-bar bg-danger" role="progressbar" style="width: 77%;" aria-valuenow="77" aria-valuemin="0" aria-valuemax="100"></div>
											</div>
											<small class="text-muted float-left m-t-5 mb-3">
												Saldo
											</small>
											<small class="text-muted float-right m-t-5 mb-3 counter" data-count="2">R$ 2,00</small>
                      <br>
                    </div>
									</div>
								</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <request-modal id="request-modal"/>
    <create-template-modal id="create-template-modal"/>
    <send-template-modal id="send-template-modal"/>
    <m-crop id="m-crop"/>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment';
import PageHeader from '@/components/PageHeader.vue';
import RequestModal from '@/components/rcs/RequestModal.vue';
import CreateTemplateModal from '@/components/whatsapp/CreateTemplateModal.vue';
import SendTemplateModal from '@/components/whatsapp/SendTemplateModal.vue';
import RCSService from '@/services/rcs.service';
import Swal from 'sweetalert2';
import MCrop from '@/components/MCrop.vue';

export default {
  name: 'MailDashboard',
  components: {
    PageHeader,
    RequestModal,
    CreateTemplateModal,
    SendTemplateModal,
    MCrop,
  },
  data() {
    return {
      fetched: false,
      fetchedItens: false,
      client: {},
      templates: [],
      messages: [],
      campaigns: [],
      tab: 'messages',
      pages: 1,
      lists: [],
      form: {
        page: 1,
        created_at: {},
      },
      options: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        maxSpan: {
          days: 31,
        },
        locale: {
          format: 'DD/MM/YYYY',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
    };
  },
  created() {
    this.fetch();
    this.changeDate();
    this.$root.$on('rcsclient.submit', this.clientMake);
  },
  methods: {
    changeDate(date) {
      if (date) {
        this.beginDate = moment(date[0], 'DD/MM/YYYY').format(
          'YYYY-MM-DD 00:00:00',
        );
        this.endDate = moment(date[1], 'DD/MM/YYYY').format(
          'YYYY-MM-DD 23:59:59',
        );
      } else {
        this.beginDate = moment().format('YYYY-MM-DD 00:00:00');
        this.endDate = moment().format('YYYY-MM-DD 23:59:59');
      }
      this.form.created_at.gte = this.beginDate;
      this.form.created_at.lte = this.endDate;
    },
    fetch() {
      Promise.all([
        RCSService.getAgents(),
      ])
        .then(
          (responses) => {
            console.log('Agents');
            console.log(responses);
            this.client = responses[0].data;
            this.fetched = true;
            this.form.client_id = this.client.id;
            this.fetchMessages();
          },
          (error) => {
            this.content = error;
          },
        )
        .finally(() => {
          this.fetched = true;
        });
    },
    deleteTemplate(id) {
      Swal.fire({
        title: this.$t('generic-str.r-sure'),
        text:
          this.$t('template-component.warning'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
      }).then((result) => {
        if (result.isConfirmed) {
          this.fetchedItens = false;
          Promise.all([
            RCSService.deleteTemplate(id, this.form),
          ])
            .then(
              (responses) => {
                console.log(responses);
                this.fetchTemplates();
              },
              (error) => {
                this.content = error;
              },
            )
            .finally(() => {
              this.fetchedItens = true;
            });
        }
      });
    },
    fetchTemplates() {
      this.fetchedItens = false;
      Promise.all([
        RCSService.getTemplates(this.form),
      ])
        .then(
          (responses) => {
            console.log(responses[0]);
            if (responses[0].data.data) {
              this.templates = responses[0].data.data;
            }
            this.fetchedItens = true;
          },
          (error) => {
            this.content = error;
          },
        )
        .finally(() => {
          this.fetchedItens = true;
        });
    },
    fetchMessages() {
      this.fetchedItens = false;
      Promise.all([
        RCSService.getMessages(this.form),
      ])
        .then(
          (responses) => {
            console.log(responses);
            if (responses[0].data.data) {
              this.messages = responses[0].data.data;
            }
            this.fetchedItens = true;
          },
          (error) => {
            this.content = error;
          },
        )
        .finally(() => {
          this.fetchedItens = true;
        });
    },
    tabChange(t) {
      this.tab = t;
    },
    callModal(id) {
      this.$root.$emit('show.modal', id, { client_id: this.client.id });
    },
    clientMake(client) {
      this.client = client;
    },
  },
};
</script>

<style scoped lang="scss">
.btn i {
  line-height: 1;
  color: inherit;
}
.m-left-5{
  margin-left: 5px;
}
</style>
